<template>
  <v-container fluid>
    <div>
      <h1 class="text-h4">{{ $lang.header.userDetails }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row wrap no-gutters class="pb-4">
        <v-col
          cols="12"
          sm="12"
          lg="8"
          xl="6"
        >
          <v-card class="pa-2">
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row wrap no-gutters justify="space-between" class="pb-1">
                <v-col
                  cols="12"
                  sm="9"
                  class="pr-sm-1"
                >
                  <v-text-field
                    v-model="user.name"
                    outlined
                    dense
                    :label="$lang.labels.name"
                    required
                    data-cy="user-name"
                    class="required-asterisk"
                    :rules="[v => !!v || $lang.labels.required]"
                    :persistent-hint="isEdit"
                    :hint="formatHint"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                  <v-btn
                    class="button-default-width"
                    color="primary"
                    @click="yesNoShow = true"
                  >
                    {{ user.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row wrap no-gutters class="pb-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="user.email"
                    outlined
                    dense
                    :label="$lang.labels.email"
                    required
                    data-cy="user-email"
                    class="required-asterisk"
                    :rules="[v => !!v || $lang.labels.required, v => validateEmail(v) || 'E-mail must be valid']"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isEdit && user" cols="12" class="pb-2 justify-space-between align-center" style="display: flex">
                  <div class="d-flex align-center">
                    <v-icon large>mdi-two-factor-authentication</v-icon>: <p class="text-h5 my-0 pb-0 pl-1 color-primary font-weight-bold">{{ user.twoFactorAuthEnabled ? 'ON' : 'OFF' }}</p>
                  </div>
                  <v-btn
                    v-if="isEdit && currentUser && user && user.twoFactorAuthEnabled && (currentUser.id === user.id || currentUserRoles.includes('USER_CREATOR'))"
                    class="ml-2 color-accent-text"
                    color="warning"
                    data-cy="disable-2fa"
                    @click="disable2fa()"
                  >
                    {{ $lang.labels.disable }}
                    <v-icon class="ml-1">mdi-two-factor-authentication</v-icon>
                  </v-btn>
                </v-col>
                <v-col v-if="isEdit" cols="12" class="pb-4 justify-space-between" style="display: flex">
                  <div style="display: flex">
                    <v-btn
                      color="accent"
                      class="color-accent-text"
                      @click="resendPassword()"
                    >
                      {{ $lang.actions.resetPassword }}
                    </v-btn>
                    <v-btn
                      v-if="isEdit && currentUser && user && (currentUser.id === user.id || currentUserRoles.includes('USER_CREATOR') || currentUserRoles.includes('SUPER_USER'))"
                      class="ml-2 color-accent-text"
                      color="primary"
                      data-cy="generate-key-user"
                      @click="generateKey()"
                    >
                      {{ $lang.actions.generateApiKey }}
                    </v-btn>
                  </div>
                  <action-button-with-confirmation
                    v-if="isEdit"
                    :action-text="$lang.actions.areYouSureSessions"
                    :action-text-suffix="user.name"
                    :title="$lang.actions.logout"
                    type="user"
                    :is-disabled="false"
                    :button-text="$lang.actions.logout"
                    :button-color="'error'"
                    :data-cy="'user-logout-all'"
                    :forced-option="false"
                    :trigger-force-logic="false"
                    :regular-delete-errors-usages="[]"
                    class="ml-2"
                    :delete-success="deleteSessionSuccess"
                    :other-than-delete="true"
                    :other-than-delete-text="$lang.labels.allSessionsDeleted"
                    btn-icon="mdi-logout-variant"
                    @submit="deleteUserSessionsFunct()"
                    @closeDialog="''"
                    @closeAfterDelete="''"
                  />
                </v-col>
                <v-col cols="12">
                  <user-roles-select
                    :role="user.roles"
                    :options="rolesSorted"
                    data-cy="user-role"
                    @changeRoles="user.roles = $event"
                  ></user-roles-select>
                </v-col>
                <v-col v-if="isEdit" cols="12">
                  <v-text-field
                    v-model="lastLoginFormatted"
                    outlined
                    dense
                    readonly
                    :label="$lang.labels.lastLogin"
                    data-cy="user-lastLogin"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters wrap justify="space-between">
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <div class="d-inline-flex align-center">
                    <v-btn
                      class="button-default-width"
                      color="secondary"
                      :to="{ name: 'users', params: { lang: $lang.current_lang } }"
                    >
                      <v-icon
                        right
                        dark
                        class="ma-0"
                      >
                        mdi-arrow-left
                      </v-icon>
                      <span class="ml-1">{{ $lang.actions.back }}</span>
                    </v-btn>
                    <action-button-with-confirmation
                      v-if="isEdit"
                      :action-text="$lang.actions.areYouSureYouWantToDelete"
                      :action-text-suffix="user.name"
                      :title="$lang.actions.delete"
                      type="user"
                      :is-disabled="!userCanDelete"
                      :button-text="$lang.actions.delete"
                      :button-color="'error'"
                      :data-cy="'user-delete'"
                      :forced-option="true"
                      :trigger-force-logic="triggerForceLogic"
                      :regular-delete-errors-usages="regularDeleteErrorsUsages"
                      class="ml-2"
                      :delete-success="deleteSuccess"
                      @submit="deleteUserFunct($event)"
                      @closeDialog="''"
                      @closeAfterDelete="$router.push({ name: 'users' })"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <div class="d-flex justify-end">
                    <div class="mr-5">
                      <v-btn
                        icon
                        color="primary"
                        class="ml-2"
                        title="Copy"
                        @click="copyFields()"
                      >
                        <v-icon>mdi-vector-combine</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="primary"
                        title="Paste"
                        class="ml-1"
                        @click="pasteFields()"
                      >
                        <v-icon>mdi-file-replace-outline</v-icon>
                      </v-btn>
                    </div>
                    <div>
                      <v-btn
                        :disabled="!valid || lock || !user.roles || !user.roles.length"
                        color="success"
                        data-cy="submit-user"
                        class="button-default-width"
                        @click="submit()"
                      >
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-if="apiKeyShow" v-model="apiKeyShow" max-width="30%">
        <v-card class="pa-2">
          <v-card-title class="pb-2">
            <span>{{ $lang.header.apiKey }}</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              color="primary"
              text
              large
              @click="apiKeyShow = false"
            >
              X
            </v-btn>
          </v-card-title>
          <v-row wrap no-gutters class="pb-4">
            <v-col
              cols="9"
              class="pr-sm-1"
            >
              <v-text-field
                v-model="apiKey"
                outlined
                dense
                :label="$lang.labels.apiKey"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="3"
            >
              <v-btn
                text
                color="primary"
                @click="copyApiKey()"
              >
                {{ $lang.actions.copyApiKey }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters justify="space-between">
            <v-btn
              color="secondary"
              text
              @click="apiKeyShow = false"
            >
              {{ $lang.actions.back }}
            </v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
      <yes-no-modal :title="$lang.actions.changeStatus" :action-text="$lang.header.sureChangeStatus" @submit="changeStatus()" @closeDialog="yesNoShow = false"></yes-no-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  disableUserUsingGET as disableUser,
  enableUserUsingGET as enableUser,
  getUserByIdUsingGET as getUser,
  updateUserUsingPUT as updateUser,
  createUserUsingPOST as createUser,
  getRolesUsingGET as getRoles,
  resendRegisterTokenUsingPOST as resendToken,
  generateApiKeyUsingPUT as generateApiKey,
  getSettingsUsingGET as getSettings,
  deleteUserUsingDELETE as deleteUser,
  deleteUserSessionsUsingDELETE as deleteUserSessions,
  twoFactorAuthDisableByUserIdUsingDELETE as twoFactorAuthDisableByUserId
} from '@/utils/api'
import copy from 'copy-to-clipboard'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import YesNoModal from '@/components/ui/modals/YesNoModal.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    YesNoModal,
    ActionButtonWithConfirmation,
    UserRolesSelect
  },
  data() {
    return {
      yesNoShow: false,
      apiKeyShow: false,
      valid: false,
      isEdit: false,
      loading: false,
      lock: false,
      user: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        email: '',
        status: '',
        roles: [],
        lastLogin: '',
        twoFactorAuthEnabled: false
      },
      roles: [],
      currentUser: null,
      currentUserRoles: [],
      apiKey: '',
      isSuperUser: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      deleteSessionSuccess: false
    }
  },
  computed: {
    rolesSorted() {
      const localRoles = structuredClone(this.roles)

      return localRoles.sort((a, b) => a.name.localeCompare(b.name))
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.user.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.user.modifiedOn)}, ID: ${this.user.id}`
    },
    lastLoginFormatted() {
      return this.$options.filters.formatDateTime(this.user.lastLogin)
    }
  },
  created() {
    this.isEdit = this.$route.name === 'userEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.currentUser = JSON.parse(localStorage.userData)
      this.currentUserRoles = this.currentUser.roles.map((x) => x.name)

      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'USER_DELETER') || this.isSuperUser
    }

    getRoles()
      .then((res) => {
        this.roles = res.data.data.items.filter((x) => x.isAssignableToUser)
      })
      .catch((err) => {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })      })

    if (this.isEdit && this.$route.params.id) {
      this.loading = true
      getUser({ id: this.$route.params.id })
        .then((res) => {
          this.user = res.data.data

          this.user.roles = res.data.data.roles.map((x) => x.id)

          this.loading = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    } else if (this.$route.params.restore) {
      this.userCanEdit = true
      this.user = this.$route.params.restore
      this.user.id = 0
      this.user.roles = this.user.roles.map((x) => x.id)
      this.loading = false
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    validateEmail(email) {
      return /^(?!\.)("([^"\\]|\\[\s\S])*"|([-\w!#$%&'*+/=?^_`{|}~]|(?<!@)\.)*)(?<!\.)@(?!-)([A-Za-z0-9-]+(\.|(?<!@)\.)*[A-Za-z0-9-]+)(?<!-)\.[A-Za-z]{2,}$/.test(email)
    },
    saveLocalStorageUserAuthData(twoFaData) {
      const userData = localStorage.getItem('userData')

      if (userData) {
        const userDataParsed = JSON.parse(userData)

        userDataParsed.twoFactorAuth = twoFaData
        localStorage.setItem('userData', JSON.stringify(userDataParsed))
      }
    },
    disable2fa() {
      this.lock = true
      twoFactorAuthDisableByUserId({ id: this.user.id })
        .then((res) => {
          if (res.status === 200) {
            this.user.twoFactorAuthEnabled = false
            this.saveLocalStorageUserAuthData(false)
            this.addSnackbar({
              message: this.$lang.success.twoFactorAuthDisabled,
              timeout: 5000,
              color: 'success'
            })
          }
          this.lock = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })          
          this.lock = false

        })
    },
    deleteUserSessionsFunct() {
      deleteUserSessions({ id: this.user.id })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSessionSuccess = true
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    deleteUserFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteUser({ id: this.user.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res?.data?.data?.usages || []
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res?.response?.data?.data?.usages || []
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    copyFields() {
      localStorage.setItem('copiedUserRoleData', JSON.stringify(this.user.roles))
    },
    pasteFields() {
      this.user.roles = JSON.parse(localStorage.getItem('copiedUserRoleData'))
    },
    copyApiKey() {
      if (this.apiKey) {
        copy(this.apiKey)

        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
        this.apiKeyShow = false

      }
    },
    async generateKey() {
      this.lock = true

      let res = null

      const { id } = this.user

      try {

        res = await generateApiKey({ id })

        if (res && res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.apiKeyGeneration,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }

        this.apiKey = res.data.data.apiKey

        this.addSnackbar({
          message: this.$lang.success.apiKeyGeneration,
          timeout: 5000,
          color: 'success'
        })

        if (this.isEdit && this.currentUser && this.currentUser.id === this.user.id) {
          localStorage.userData = JSON.stringify({ ...this.currentUser, ...res.data.data })
        }
        this.apiKeyShow = true

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })       
        this.lock = false
      }
    },
    errorHandler(err) {
      if (err.statusText) return err.statusText

      return this.isEdit ? this.$lang.errors.userUpdate : this.$lang.errors.userCreate
    },
    async submit() {
      this.lock = true

      let res = null

      const { id } = this.user

      if (!this.isEdit) {
        delete this.user.id
        this.user.status = 'ACTIVE'
      }
      delete this.user.createdOn
      delete this.user.modifiedOn

      try {

        res = this.isEdit ? await updateUser({ id, body: this.user }) : await createUser({ body: this.user })

        if (res && res.status !== 200) {
          const errorMessage = this.errorHandler(res?.response?.data || 'Error')

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.userUpdated : this.$lang.success.userCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        if (this.isEdit && this.currentUser && this.currentUser.id === this.user.id) {
          getUser({ id: this.$route.params.id })
            .then((res) => {
              localStorage.userData = JSON.stringify({ ...res.data.data, authToken: this.currentUser.authToken })

              this.$router.push({
                name: 'users',
                params: {
                  message: successMessage
                }
              })
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })            })
        } else {
          this.$router.push({
            name: 'users',
            params: {
              message: successMessage
            }
          })
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })    
        this.lock = false
      }
    },
    async resendPassword () {
      this.lock = true
      try {
        const res = await resendToken({ id: this.user.id })

        if (res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.resendPassword,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        this.addSnackbar({
          message: this.$lang.success.resendPassword,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })       
        this.lock = false
      }
    },
    async changeStatus () {
      this.lock = true
      this.yesNoShow = false
      try {
        const res = this.user.status === 'ACTIVE' ? await disableUser({ id: this.user.id }) : await enableUser({ id: this.user.id })

        if (res.status !== 200) {
          
          this.addSnackbar({
            message: this.$lang.errors.userStatusUpdate,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        const successMessage = this.user.status !== 'ACTIVE' ? this.$lang.success.userActivated : this.$lang.success.userDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.user.status = res.data.data.status

        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>
